.no-brightness {
  -webkit-filter: brightness(0%);
}

.dancing {
  animation: flipFlop 1.5s infinite;
}

.headerPadding {
  padding-top: 70px;
}

@keyframes flipFlop {
  from {
  }
  50% {
    transform: scalex(-1);
  }
  to {
  }
}

.flip-img {
  transform: scalex(-1);
}
